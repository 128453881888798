import type { ReactNode } from 'react';

import { useBumblebeeContext } from '@shieldpay/theme-provider-ui';
import type { Padding } from '@shieldpay/theme-provider-ui/theme/types';
import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import type { BoxProps } from '../box/box';
import { Box } from '../box/box';
import { getBoxStyles } from '../box/get-box-styles';
import { Text } from '../text/text';
import { getTextStyles } from '../text/text-styles';

import * as styles from './primary-nav.css';

export type PrimaryNavProps = Pick<
  BoxProps,
  'background' | 'gridItem' | 'children'
>;

export interface NavItem {
  children: ReactNode;
  disabled?: boolean;
  to: string;
}

export interface NavHeadingProps {
  children: ReactNode;
}

const PrimaryNavHeading = ({ children }: NavHeadingProps) => (
  <Text
    Component="li"
    variant="section"
    color="neutral250"
    padding={{ left: 'basePos3', y: 'base' }}
  >
    {children}
  </Text>
);

const linkPadding = { left: 'basePos3', y: 'base' } satisfies Padding;

const PrimaryNavItem = ({ disabled = false, children, to }: NavItem) => {
  const { NavLink } = useBumblebeeContext();

  const className = classFilter([styles.link, !disabled && styles.enabled]);

  return (
    <Box Component="li">
      {disabled ? (
        <Text color="neutral250" padding={linkPadding} className={className}>
          {children}
        </Text>
      ) : (
        <NavLink
          className={classFilter([
            className,
            getTextStyles({
              variant: 'bodyMedium',
            }),
            getBoxStyles({ color: 'neutral000', padding: linkPadding }),
          ])}
          to={to}
          prefetch="intent"
        >
          {children}
        </NavLink>
      )}
    </Box>
  );
};

export const PrimaryNav = ({
  children,
  background,
  gridItem,
}: PrimaryNavProps) => {
  return (
    <Box
      background={background}
      Component="nav"
      gridItem={gridItem}
      // TODO i18n
      aria-label="Primary navigation"
    >
      <Box Component="ul">{children}</Box>
    </Box>
  );
};

PrimaryNav.Item = PrimaryNavItem;
PrimaryNav.Heading = PrimaryNavHeading;
